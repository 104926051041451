<template>
<section class="px-3 pb-1">
  <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
  <div class="pt-6 text-left" style="font-size:.9rem">
    <h6>CARA MENDAPATKAN SKOR</h6>
    <p>
      <ul class="pl-4">
        <li>Pemain harus mengenai icon bintang untuk mendapatkan skor</li>
        <li>1 icon bintang menjadi 1 skor yang didapatkan</li>
        <li>Skor di akumulasikan untuk semua game</li>
        <li>Bisa mendapatkan hadiah langsung jika mencapai skor yang ditentukan</li>
        <li>Skor wajib ditukarkan dengan hadiah di program pesta reward</li>
        <li>Priode pesta reward berlangsung selama 120hr </li>
      </ul>
    </p>
    <h6>CARA BERMAIN</h6>
    <p>BOLA HOKI
      <ol class="pl-4">
        <li>Bola hoki adalah permainan adu penalty ,pemain bisa menjadi penendang </li>
        <li>Pemain bisa mengumpulkan banyak skor jika penendang mengenai icon bintang di area gawang.</li>
        <li>1 token, pemain mendapatkan 30x tendangan.</li>
        <li>Pemain bisa mendapatkan hadiah langsung dari game bola hoki , jika mencapain skor yang ditentukan.</li>
        <li>Di permainan ini cuma terdapat satu lapangan saja.</li>
        <li>Game Bola hoki ini masuk dalam program pesta reward.</li> 
        <li>Penukaran skor bersifat wajib untuk peserta.</li>
      </ol>
    </p>
    <p>BALAP HOKI
      <ol class="pl-4">
        <li>Permainan balap mobil yang didalam trek nya ada tantangan</li>
        <li>Pemain bisa mengumpulkan banyak skor jika menabrak icon bintang didalam trek.</li> 
        <li>1 token bisa dimainkan 3 trek untuk game balap hoki</li> 
        <li>Pemain bisa mendapatkan hadiah langsung dari game balap hoki , jika mencapai skor yang di tentukan.</li> 
        <li>Game ini terdapat  trek yang berbeda beda.</li>
        <li>Setiap trek dimainkan dengan durasi yang berbeda beda dengan tingkat level nya</li>
        <li>Game balap hoki ini masuk dalam program pesta reward.</li>
        <li>Penukaran skor bersifat wajib untuk peserta. </li>
      </ol>
    </p>
    <p>KETOKOO
      <ol class="pl-4">
        <li>Permainan tap market , dimana pemain menjadi kasir dan mejaga stok barang yang ada ditoko</li>
        <li>Permainan yang mengatur pembeli supaya tidak terjadi antrian Panjang saat melakukan pembayaran di kasir.</li>
        <li>10 pelanggan sukses melakukan pembayaran dikasir , akan mendapatkan 1skor.</li>
        <li>Dibatasi waktu saat melakukan pembayaran dikasir supaya pelanggan tidak kecewa.</li>
        <li>Pemain bisa mendapatkan hadiah langsung , jika skor yang di kumpulkan sesuai dengan ketentuan yang berlaku.</li> 
        <li>Game ketokoo ini masuk dalam program pesta reward.</li>
        <li>Penukaran skor bersifat wajib untuk peserta.</li>
      </ol>
    </p>
    <p>ULAR TENGGO
      <ol class="pl-4">
        <li>Permainan seperti ular tangga , namun dibuat dengan user experience yang berbeda didalam game terdapat hadiah menarik yang dimenangkan.</li> 
        <li>Pemain mengumpulkan skor dengan cara menginjak icon bintang pada papan permainan.</li> 
        <li>Jika pemain menginjak bom yang ada dipapan permainan , pemain akan mengulang lagi dari awal.</li>
        <li>Jika pemain menginjak ular , pemain akan turun  , jika pemain menginjak tangga , pemain akan naik.</li>
        <li>Tematik pada papan permainan.</li>
        <li>1 sesi dimainkan sampai ke finish.</li>
        <li>Untuk mencapai finish , jika angka dadu tidak sesuai dengan kotak jalan , maka tidak bergerak dan dilanjutkan kocok dadu untuk lawan.</li>
        <li>Game Ular Tenggo ini masuk dalam program pesta reward.</li>
        <li>Penukaran skor bersifat wajib untuk peserta.</li>
      </ol>
    </p>
  </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex' 
import Header from '@/components/Header.vue'
export default {
  name: 'Faq',
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Cara Bermain",
      showToken: false,
      showBg: true,
    };
  },
  // mounted() {
  //   console.log(this.store, this.coin);
  // },
  computed: {
    ...mapState({
      coin: 'coin',
    })
  },
  methods: {
    goTo(page) {
        this.$router.push({ name: page });
    },
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
